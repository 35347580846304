
import 'vue-tel-input/dist/vue-tel-input.css';
import { ALL_COUNTRIES } from '~/vars/all_countries';
export default {
  components: {},
  mixins: [],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showMore: false,
    };
  },
  computed: {
    ALL_COUNTRIES() {
      return ALL_COUNTRIES;
    },
  },
  watch: {},
  methods: {},
  /*
   ** Lyfecycle hooks
   */
  created() {},
  mounted() {},
  updated() {},
};
