
export default {
  props: {
    index: {
      type: Number,
      required: true,
    },
    ticket: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    label() {
      return this.ticket ? 'Ticket ' : 'Copy ';
    },
  },
};
