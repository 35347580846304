
import { convertToInternationalCurrencySystem } from '~/utils/functions';
import { CURRENCIES, CURRENCY_FORMAT_CONFIGURATION } from '~/vars/api';
export default {
  props: {
    currency: {
      type: String,
      default: 'USD',
    },
    amount: {
      type: Number,
      default: 0,
    },
    i18nPath: {
      type: String,
      default: undefined,
    },
    i18nSubObject: {
      type: Object,
      default: undefined,
    },
    settings: {
      type: Object,
      default: () => {},
    },
    shortenAmount: {
      type: Boolean,
      default: false,
    },
    format: {
      type: Number,
      default: 7,
    },
    styles: {
      type: String,
      default: '',
    },
  },
  computed: {
    symbolPosition() {
      return {
        symbolPosition:
          this.currency.toLowerCase() === 'satoshi' ? 'back' : 'front',
        symbolSpacing: this.currency.toLowerCase() === 'satoshi',
      };
    },
    defaultConfiguration() {
      return Object.assign(CURRENCY_FORMAT_CONFIGURATION, this.symbolPosition);
    },
    customizedConfiguration() {
      return Object.assign(this.defaultConfiguration, this.settings);
    },
    formatCurrency() {
      const currency = Object.assign(
        { symbol: CURRENCIES[this.currency.toLowerCase()].symbol },
        this.customizedConfiguration,
      );
      return currency;
    },
    text() {
      if (this.i18nPath)
        return this.$t(
          this.i18nPath,
          Object.assign(this.i18nSubObject ? this.i18nSubObject : {}, {
            amount: this.amountFullFormated,
          }),
        );
      return this.amountFullFormated;
    },
    isShortened() {
      return this.shortenAmount && this.amountInString.length >= this.format;
    },
    amountInString() {
      return `${this.amount}`;
    },
    amountFullFormated() {
      return this.isShortened
        ? this.formatShortenAmount()
        : this.formatAmount();
    },
    amountFormated() {
      return this.formatAmount();
    },
  },
  methods: {
    formatAmount() {
      return this.$currency.format(this.amount, this.formatCurrency);
    },
    formatShortenAmount() {
      let shortened = convertToInternationalCurrencySystem(this.amountInString);
      if (this.currency.toLowerCase() === 'satoshi') return `${shortened} Sats`;
      else
        return `${CURRENCIES[this.currency.toLowerCase()].symbol}${shortened}`;
    },
  },
};
