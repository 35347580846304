
import {
  convertMiliseconds,
  handleErrors,
  remainingToJoinHumanString,
} from '~/utils/functions';
import { GROUP_TYPE_AVAILABLE, GROUP_TYPE_OFFERING } from '~/vars/api';
import BaseCurrencyAmount from '../base/BaseCurrencyAmount.vue';
import ProductCopyName from './base/ProductCopyName.vue';
import TokenTag from './base/TokenTag.vue';
import UserDependency from './base/UserDependency.vue';
export default {
  components: { BaseCurrencyAmount, UserDependency, ProductCopyName, TokenTag },
  props: {
    product: {
      type: Object,
      required: true,
    },
    involved: {
      type: Object,
    },
    tokensGroup: {
      type: Object,
    },
    size: {
      type: Number,
      default: 150,
    },
    minHeight: {
      type: Number,
      default: 100,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    showCollection: {
      type: Boolean,
      default: true,
    },
    showResell: {
      type: Boolean,
      default: true,
    },
    showCopies: {
      type: Boolean,
      default: false,
    },
    showInvolved: {
      type: Boolean,
      default: false,
    },
    showImage: {
      type: Boolean,
      default: true,
    },
    bidCopies: {
      type: String,
      default: null,
    },
    buyCopies: {
      type: String,
      default: null,
    },
    copies: {
      type: Number,
      default: undefined,
    },
    maxCopies: {
      type: Number,
      default: null,
    },
    showBid: {
      type: Boolean,
      default: false,
    },
    initialBid: {
      type: String,
      default: null,
    },
    bidCurrency: {
      type: String,
      default: 'USD',
    },
    showExpireDate: {
      type: Boolean,
      default: false,
    },
    expireDate: {
      type: Number,
      default: null,
    },
    showRemaining: {
      type: Boolean,
      default: false,
    },
    showBuy: {
      type: Boolean,
      default: false,
    },
    initialBuy: {
      type: String,
      default: null,
    },
    buyCurrency: {
      type: String,
      default: 'USD',
    },
    enabledShowProduct: {
      type: Boolean,
      default: true,
    },
    horizontalCard: {
      type: Boolean,
      default: false,
    },
    redeemed: {
      type: Boolean,
      default: false,
    },
    ticket: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    showTag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { collection: '' };
  },
  computed: {
    img() {
      let image;
      if (this.product.contract.image.charAt(0) === '[') {
        image = JSON.parse(this.product.contract.image).map(
          (item) => item + (item.endsWith('?alt=media') ? '' : '?alt=media'),
        )[0];
      } else
        image =
          this.product.contract.image +
          (this.product.contract.image.endsWith('?alt=media')
            ? ''
            : '?alt=media');
      return image;
    },
    title() {
      return this.product.contract.name;
    },
    fromCollection() {
      return false;
      // return Boolean(this.product.contract.issuercollection);
    },
    collectionLabel() {
      return this.product.contract.issuercollection.name;
    },
    // collectionRoute() {
    //   return this.localePath({
    //     name: 'market-collection-id',
    //     params: {
    //       id: this.product.contract.issuercollection.collectionid,
    //     },
    //   });
    // },
    resellLabel() {
      return this.$t(
        `market.product.base.resell.${
          this.product.contract.transferrable === true ? 'can' : 'cannot'
        }`,
      );
    },
    // hasRoyalty() {
    //   return (
    //     this.product.contract.commissionschema &&
    //     this.product.contract.commissionschema.royalties &&
    //     Boolean(this.product.contract.commissionschema.royalties.amount)
    //   );
    // },
    // royaltyLabel() {
    //   return this.$t(`market.product.base.royalty`, {
    //     royalty: this.product.contract.commissionschema.royalties.amount,
    //     type: this.$t(
    //       `base.commission_type.${this.product.contract.commissionschema.royalties.type}`,
    //     ),
    //   });
    // },
    actualBidCopies() {
      return this.bidCopies
        ? this.bidCopies
        : this.isOffering
        ? this.tokensGroup.offering.allowbid
          ? this.tokensGroup.nftlist.length
          : 0
        : this.product.groups.find(({ type }) => type === GROUP_TYPE_AVAILABLE)
            .nftlist.length;
    },
    actualBuyCopies() {
      return this.buyCopies
        ? this.buyCopies
        : this.isOffering
        ? this.tokensGroup.offering.allowbuy
          ? this.tokensGroup.nftlist.length
          : 0
        : this.product.groups.find(({ type }) => type === GROUP_TYPE_AVAILABLE)
            .nftlist.length;
    },
    availableCopies() {
      return this.copies !== undefined
        ? this.copies
        : this.product.groups.reduce(
            (total, item) => total + item.nftlist.length,
            0,
          );
    },
    totalCopies() {
      return this.maxCopies
        ? this.maxCopies
        : this.product.contract.totalSupply;
    },
    bid() {
      return this.tokensGroup.offering.minimbid
        ? this.tokensGroup.offering.minimbid
        : this.tokensGroup.offering.initialbidprice;
    },
    bidPrice() {
      return this.isOffering ? this.bid : this.initialBid;
    },
    bidPriceCurrency() {
      if (this.isOffering) {
        return this.tokensGroup.offering.currency;
      } else {
        return this.bidCurrency;
      }
    },
    bidText() {
      return {
        text: this.$t(
          `market.product.offers.${
            this.isOffering && this.tokensGroup.offering.minimbid
              ? 'min_bid'
              : 'initial_bid'
          }`,
        ),
      };
    },
    saleDate() {
      return this.expireDate
        ? this.expireDate
        : this.tokensGroup.offering.enddatestamp;
    },
    expireText() {
      return this.$t('market.product.remaining.title', {
        date: this.$moment(this.saleDate).format('DD/mm/YYYY'),
        hour: this.$moment(this.saleDate).format('kk:mm[h]'),
      });
    },
    remainingText() {
      const remainingObject = remainingToJoinHumanString(
        convertMiliseconds(this.saleDate - new Date().getTime()),
      );
      return this.$t('market.product.remaining.header_text', {
        remaining: remainingObject,
      });
    },
    buyPrice() {
      return this.initialBuy
        ? this.initialBuy
        : this.tokensGroup.offering.buyprice
        ? this.tokensGroup.offering.buyprice
        : '';
    },
    buyPriceCurrency() {
      if (this.isOffering) {
        return this.tokensGroup.offering.currency;
      } else {
        return this.buyCurrency;
      }
    },
    buyText() {
      return {
        text: this.$t('market.product.actions.buy_now'),
      };
    },
    info() {
      return this.$t(
        `wallet.my_assets.details${this.horizontalCard ? '.of_total.' : '.'}${
          this.isTicket ? 'tickets' : 'copies'
        }.info`,
        {
          used: this.availableCopies,
          total: this.totalCopies,
        },
      );
    },
    infoInvolved() {
      return this.$t(
        `wallet.my_assets.details${this.horizontalCard ? '.of_total.' : '.'}${
          this.isTicket ? 'tickets' : 'copies'
        }.info`,
        {
          used: this.availableCopies,
          total: this.totalCopies,
        },
      );
    },
    infoBid() {
      return this.$t(
        `wallet.my_assets.details${this.horizontalCard ? '.of_total.' : '.'}${
          this.isTicket ? 'tickets' : 'copies'
        }.bid`,
        {
          used: this.actualBidCopies,
          total: this.totalCopies,
        },
      );
    },
    infoBuy() {
      return this.$t(
        `wallet.my_assets.details${this.horizontalCard ? '.of_total.' : '.'}${
          this.isTicket ? 'tickets' : 'copies'
        }.now`,
        {
          used: this.actualBuyCopies,
          total: this.totalCopies,
        },
      );
    },
    isOffering() {
      return this.tokensGroup && this.tokensGroup.type === GROUP_TYPE_OFFERING;
    },
    isTicket() {
      return this.product.contract.is_ticket;
    },
    ticketExpiration() {
      return this.$t('wallet.my_assets.details.ticket_expiration', {
        date: this.$moment(
          this.product.contract.ticketExpirationDatestamp,
        ).format('YYYY-MM-DD [at] kk:mm'),
      });
    },
    getCreator() {
      return this.product.contract.issuernick;
    },
    // enabledShowProduct() {
    //   return (
    //     (this.$route.params.product &&
    //       this.$route.params.product !== this.product.id) ||
    //     !this.$route.params.product
    //   );
    // },
  },
  methods: {
    redirectTo() {
      if (this.enabledShowProduct) {
        const id = this.isOffering
          ? this.tokensGroup.offeringid
          : this.product.contract.tokenSerieId;
        this.$router.push(
          this.localeRoute({
            name: 'market-product-id',
            params: {
              id,
            },
            query: {
              type: this.tokensGroup.type,
            },
          }),
        );
      }
    },
  },
};
