
import UserMixinHandler from '~/components/user/UserMixinHandler.vue';
import { showUserModal } from '~/vars/store/actions';
export default {
  mixins: [UserMixinHandler],
  props: {
    loggedUser: {
      type: Boolean,
      default: false,
    },
    userNick: {
      type: String,
    },
    dependency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    userName() {
      return this.loggedUser ? this.$t('base.you') : this.user.name;
    },
  },
  methods: {
    showUserModal(user) {
      this.$store.dispatch(`users/${showUserModal}`, { user });
    },
  },
};
