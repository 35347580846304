
import { ALL_COUNTRIES } from '~/vars/all_countries';
import { GROUP_TYPE_OFFERING } from '~/vars/api';
import HandleShowShippingModal from '../modals/HandleShowShippingModal.vue';
import CountryList from './CountryList.vue';
import PostalAddress from './PostalAddress.vue';
export default {
  components: { CountryList, PostalAddress, HandleShowShippingModal },
  mixins: [],
  props: {
    contract: {
      type: Object,
    },
    type: {
      type: String,
      required: true,
    },
    tokensGroup: {
      type: Object,
    },
    status: {
      type: String,
    },
  },
  data() {
    return {
      showModal: false,
      showMore: false,
      variantOptions: {
        ['token-type-physical']: 'primary',
        ['token-type-digital']: 'primary',
        ['token-type-service']: 'secondary',
        ['token-type-ticket']: 'tertiary',
        ['token-type-investment']: 'quartiary',
        ['token-status-buy_now']: 'primary',
        ['token-status-auction']: 'primary',
        ['token-status-available']: 'secondary',
        ['token-status-deleted']: 'tertiary',
        ['token-status-expired']: 'tertiary',
        ['token-status-others']: 'tertiary',
        ['token-status-pending']: 'tertiary',
        ['token-status-not_validated']: 'tertiary',
      },
      iconOptions: {
        ['token-type-physical-delivery']: 'physical_product_delivery',
        ['token-type-physical-pickup']: 'physical_product_pickup',
        ['token-type-physical']: 'physical_product_pickup',
        ['token-type-digital']: 'digital_product',
        ['token-type-service']: 'service_icon',
        ['token-type-ticket']: 'ticket_icon',
        ['token-type-investment']: 'investment_icon',
      },
    };
  },
  computed: {
    ALL_COUNTRIES() {
      return ALL_COUNTRIES;
    },
    offering() {
      if (this.tokensGroup && this.tokensGroup.type === GROUP_TYPE_OFFERING)
        return this.tokensGroup.offering;
      else return null;
    },
    tagLabel() {
      return this.$t(`base.tags.${this.type}.${this.option}`);
    },
    hoverTag() {
      if (
        this.type === 'token-type' &&
        this.offering &&
        this.tagLabel.toLowerCase().includes('physical')
      ) {
        let option = 'shipping';
        if (
          this.offering.selectedcountries !== null &&
          this.offering.postaladdress === null
        )
          option = 'delivery';
        if (
          this.offering.selectedcountries === null &&
          this.offering.postaladdress !== null
        )
          option = 'pickup';
        return option;
      } else return null;
    },
    hoverTagLabel() {
      if (this.hoverTag) return this.$t(`base.tags.shipping.${this.hoverTag}`);
      else return null;
    },
    iconTag() {
      if (this.type === 'token-type') {
        return this.iconOptions[
          `${this.type}-${this.option}${
            this.hoverTagLabel ? `-${this.hoverTag}` : ''
          }`
        ];
      } else return null;
    },
    option() {
      let option;
      if (this.type === 'token-type') {
        if (this.contract.is_ticket) option = 'ticket';
        else if (this.contract.product_type)
          option = this.contract.product_type;
        else if (this.contract.token_type) option = this.contract.token_type;
        else option = 'service';
      }
      if (this.type === 'token-status') {
        if (
          this.status === 'expired' ||
          (this.contract.is_ticket === true &&
            this.tokensGroup.type === 'available' &&
            this.contract.ticketExpirationDatestamp < new Date().getTime())
        )
          option = 'expired';
        else option = this.status;
      }
      return option;
    },
    variant() {
      return this.variantOptions[`${this.type}-${this.option}`];
    },
    shippingTypeComponent() {
      if (this.hoverTag.includes('delivery')) return 'country-list';
      if (this.hoverTag.includes('pickup')) return 'postal-address';
    },
    itemsToShow() {
      if (this.hoverTag.includes('delivery')) {
        if (this.offering.selectedcountries.length === ALL_COUNTRIES.length)
          return ['ww'];
        if (this.showMore || this.offering.selectedcountries.length < 6)
          return this.offering.selectedcountries;
        return this.offering.selectedcountries.slice(0, 5);
      } else if (this.hoverTag.includes('pickup')) {
        return this.offering.postaladdress;
      }
    },
    areMore() {
      return this.itemsToShow.length < this.offering.selectedcountries.length;
    },
    moreLabel() {
      if (this.shippingTypeComponent.includes('country'))
        return this.$t(`base.see_${this.showMore ? 'less' : 'n_more'}`, {
          n: this.offering.selectedcountries.length - this.itemsToShow.length,
        });
      else if (this.shippingTypeComponent.includes('postal-address'))
        return this.$t('base.see_maps');
    },
  },
  watch: {},
  methods: {
    onActionClicked() {
      if (this.shippingTypeComponent.includes('country'))
        this.showMore = !this.showMore;
      else if (this.shippingTypeComponent.includes('postal-address')) {
        let params = Object.entries(this.offering.postaladdress).reduce(
          (params, field) => {
            if (!['instructions', 'id'].includes(field[0]) && field[1] !== null)
              params = `${params !== '' ? params + ',' : params}${field[1]}`;
            return params;
          },
          '',
        );
        window.open(
          `https://www.google.com/maps/search/${encodeURIComponent(params)}`,
          '_blank',
        );
      }
    },
  },
  /*
   ** Lyfecycle hooks
   */
  created() {},
  mounted() {},
  updated() {},
};
