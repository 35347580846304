
export default {
  components: {},
  mixins: [],
  props: {
    label: {
      type: String,
      required: true,
    },
    hoverLabel: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: true,
      validator: (val) =>
        ['profile', 'token-type', 'token-status'].includes(val),
    },
    variant: {
      type: String,
      required: false,
      validator: (val) =>
        ['primary', 'secondary', 'tertiary', 'quartiary'].includes(val),
    },
  },
  data() {
    return {
      onHover: false,
    };
  },
  computed: {},
  watch: {},
  methods: {},
  /*
   ** Lyfecycle hooks
   */
  created() {},
  mounted() {},
  updated() {},
};
