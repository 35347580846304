
import 'vue-tel-input/dist/vue-tel-input.css';
import { ALL_COUNTRIES } from '~/vars/all_countries';
export default {
  components: {},
  mixins: [],
  props: {
    items: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ALL_COUNTRIES() {
      return ALL_COUNTRIES;
    },
    fullName() {
      return this.items.fullname;
    },
    address1() {
      return this.items.line1;
    },
    address2() {
      return this.items.line2;
    },
    address3() {
      return `${this.items.city}, ${this.items.stateprovince}`;
    },
    address4() {
      return `${
        this.ALL_COUNTRIES.find(({ code }) => code === this.items.country)
          .country
      }, ${this.items.postalcode}`;
    },
    instructions() {
      return this.items.instructions;
    },
  },
  watch: {},
  methods: {},
  /*
   ** Lyfecycle hooks
   */
  created() {},
  mounted() {},
  updated() {},
};
